import React from 'react';

export const HoiAn = {
    "name": "Hoi An",
    "link": "hoi-an",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50379724318_0bf0283de5_t.jpg",
    "image": "https://live.staticflickr.com/65535/50379724318_e1d9457c76_o.jpg",
    "caption": "The City of Lanterns",

    "description": "With its golden beaches, flavorful cuisine and UNESCO-listed Ancient Town, Hoi An is considered as one of the most beautiful destinations in Southeast Asia. You’ll find plenty to do here, from exploring old, historic buildings, to dining at hipster cafes and restaurants, and even spending a day at the beach.",

    "nrDays": <p>Two days are good enough to visit the city and to experiment some of the tours and cooking classes that this place has to offer. If you want to add My Son Sanctuary to your itinerary we recommend adding an extra day.
              </p>,

    "whenVisit": <p>
                Hoi An is a great during all months of the year. In terms of weather, it’s consistently hot, although watch out for rain during some months. The wet season ranges from September to January, whilst the dry season is from February to August.
                <br/><br/>
                When you planning your trip consider to visit Hoi An during the <b>Lantern Festival</b>. This Festival is run on the 14th day of each lunar moon when the moon is at its fullest and brightest. Although this has become so popular that even if your visit isn’t on a full moon date you will still see celebrations of Hoi An traditional full moon festival almost every night.
                 </p>,

    "mobility": <p>
                Getting around in Hoi An is fairly easy as most roads, walkways and bridges are well maintained. The ancient town is <b>pedestrian only</b>, meaning you must park your scooter or bike outside, there are designated areas for that. The city is quite small so you can easily explore by walking.
                <br/><br/>
                The biggest exception is <b>My Son Sanctuary</b> that is very dislocated from the city. For that you have three options. You can rent a bike or a car by your own (make sure you have the license for it), or rent a bike/car that picks you up from Hoi An and waits for you at the Sanctuary, or the “easiest” option, book a My Son tour. If you choose one of the last two option we recommend <a href=" https://vmtravel.com.vn/" target="_blank" rel="nonopened nonrelated">VM Travel</a>.
                <br/><br/>
                To get to Hoi An you can get a bus or a train if you are arriving from <a href="/travel-guides/asia/vietnam/da-nang" >Da Nang</a>. The other option is to rent a bike or a car, that gives you more freedom in your schedules. The nearest airport is also in <a href="/travel-guides/asia/vietnam/da-nang" >Da Nang</a>. You can grab a taxi too, but make sure to negotiate the fare before hopping on the vehicle.
                </p>,

    "safety": <p>Hoi An is a safe city to travel in. As in other places in Vietnam, scams are the most common issue that you probably will get here. Ask always for personal recommendations from other travelers or from your hotel if you’re going to do some paid tours.
            </p>,

    "itinerary": {
        "description": "As described before, we recommend at least 2 days to see the city, and 3 if you want to join the visit to My Son Sanctuary. To see the city of Hoi An two days are good to welcome the beauty and the culture of it. We will suggest here a 3-days itinerary incorporating the visit to My Son Sanctuary. It will not be mentioned on this itinerary, but if you want to explore the Rice Paddy Fields, in Tra Que Vegetable Village, you can schedule a day trip from here too.",
        "days": [
            {
                "description":
                    <p>
                        In your first day you can start with a good traditional breakfast at the Central Market. During this day we mostly suggest you to wander thought the streets of this amazing Ancient Town. You can make some stops at famous attractions as you walk pass them. We recommend to visit the Fukian Assembly Hall, the Old House of Tan Ky, one of Hoi An’s cultural heritage, and the Japanese Cover Bridge. Get some rest between the attractions and don’t forget to taste the coffee on a sidewalk café. To finish your afternoon you can wait for the sunset at the An Bang Beach. At night embrace the city lights and, if you have the chance to do it, enjoy the Lantern Festival.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50504294643_76d7c41620_o.jpg",
                "caption": "Hoi An's river lights"
            },
            {
                "description": <p>
                On this 3-day itinerary we suggest a visit My Son Sanctuary on the second day. It’s always easier if you are around town on the last day of your stay. For this visit you have two options. Get an early trip to My Son (it opens at 6am and closes at 5pm) and watch the sunrise there. If want to avoid large groups and be more isolated, visit it after lunch. If you opt for this second option you can get a walk by the city and eat a good breakfast, or make the trip to My Son with some small stops. At Night take a time to relax in some bar near the river and observe the beauty of the Old Town.
                </p>,
                "image": "https://live.staticflickr.com/65535/50504294618_1cbedcbba5_o.jpg",
                "caption": "My Son ruins."
            },
            {
                "description": <p>
                No trip to Hoi An would be fulfilled without a gastronomic experience. With that in mind, on your last day you should visit some of the foodies’ shops scattered around the Old Town. If you want to learn more about the Vietnamese cuisine you can join one of the available food tours. It takes almost 3 hours, and will give you a different experience. The Original Taste of Hoi An, Vietnam Vespa Adventures and Hoi An Food Tour – Private Day Tours are some of the top-rated tour companies in Hoi An offering excellent food tours.
                <br/><br/>
                If you are searching for a more "<i>do it yourself</i>" experience, take a traditional cooking class, where you will learn to cook some popular dishes. Make sure you read about the class you are about to take. Personally, we recommend Green Bamboo Cooking School & Café. Attached to this, Hoi An is also renowned around the world for its expert tailors and incredibly affordable prices. If you are searching for a new suit, it’s a great opportunity to do it.
                </p>,
                "image": "https://live.staticflickr.com/65535/50504294593_0f4cd8066b_o.jpg",
                "caption": "Hoi An Old Town."
            }
        ]
    },

    "highlights": [
        {
            "name": "Lantern Festival",
            "description": "Lanterns are lit every night after the sunset in Hoi An. Candles are placed at the bottom of the colorful paper lanterns. The Hoi An lantern festival is a gorgeous tradition. This started centuries ago, where during a full moon people from all over the region would come to participate.",
            "image": "https://live.staticflickr.com/65535/50504294573_9927cec77d_o.jpg"
        },
        {
            "name": "Japanese Covered Bridge",
            "description": "The Japanese Covered Bridge is an iconic landmark in the centre of Old Town, and must be included in your itinerary. The Japanese architecture contrasts the French buildings on either side, which makes it a great location to see the architecture from both eras side by side.",
            "image": "https://live.staticflickr.com/65535/50504294563_e1a59c99d6_o.jpg"
        },
        {
            "name": "Old House of Tan Ky",
            "description": "Located in the centre of Old Town, you can visit this house during your sidewalks. Also you can take an Hoi An Ancient Town Walking Tour, to learn more about it. Combining Chinese and Japanese influences, is surely a place to go.",
            "image": "https://live.staticflickr.com/65535/50505168157_9e44b35015_o.jpg"
        },
        {
            "name": "My Son Ruins",
            "description": "This is another UNESCO World Heritage Site that has been restored and is well worth a visit when you’re in the area. Considered by some as a small version of Angkow Wat, this ancient architectural ruins are located in the middle of the forest. The entrance fee for this place is around 7$, and if you want to hire a guide you will have to pay an extra of 5$.",
            "image": "https://live.staticflickr.com/65535/50505008521_f42800411a_o.jpg"
        },
        {
            "name": "Tra Que Vegetable Village",
            "description": "Hoi An is surrounded by rice paddy fields which are fully functioning and run by locals to earn a living. If you have the time, you can get out of the busy city centre and explore the countryside. This village is a locally-run organic farm.",
            "image": "https://live.staticflickr.com/65535/50504294523_aac5098a58_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Vietnamese cuisine differs region by region. Each city, sometimes, even each village has its own list of distinctive local specialties. But when we speak of Vietnamese cuisine, it's impossible not to mention Hoi An. This area has a lot of traditional dishes and offers to tourists an huge amount of opportunities to do food walking tours or even to take cooking classes. We really recommend to take at least one of them, because it is a unique opportunity to learn with the locals.
            </p>,
        "restaurants": [
            {
                "name": "Banh Mi Phuong",
                "description": "Opened for more than 30 years, and with a unique special sauce, this place is a good option to try Banh Mi.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50504294498_b68cca5137_o.jpg"
            },
            {
                "name": "Nhan's Kitchen",
                "description": "All dishes are cooked with super fresh ingredients and are absolutely delicious. The price is very good too.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50505008446_ab7c713580_o.jpg"
            },
            {
                "name": "Restaurant & Cafe Tuan",
                "description": "Affordable prices, delicious dishes, and phenomenally friendly staff. ",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50504294463_728dae5359_o.jpg"
            },
            {
                "name": "Red Bean Restaurant",
                "description": "With so many choices to eat and a nice environment, this place is a good option for breakfast, lunch and dinner.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50505008411_ff4b3c4e0a_o.jpg"
            },
            {
                "name": "The Temple Restaurant",
                "description": "With good decoration and tasty food, you will be satisfied with this restaurant. However the prices here are a little higher.",
                "price": 4,
                "image": "https://live.staticflickr.com/65535/50505008406_85811db8b9_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "Hoi An is a very touristic and small city, so you will easily find a place that covers all your expectations.",
        "budget": [
            {
                "name": "Light Sky Homestay",
                "description": "Situated close to the Japanese Cover Bridge, this is a nice place for a cheap stay. A good breakfast is served by 2$ additional dollars.",
                "image": "https://live.staticflickr.com/65535/50505008391_b9ab718f8b_o.jpg",
                "link": "/"
            },
            {
                "name": "My House Hoi An Homestay",
                "description": "Close to the previous one, My House Hoi An Homestay is a good alternative to it. With similar prices and conditions, you will be satisfied here too.",
                "image": "https://live.staticflickr.com/65535/50504294428_89f1aeaab6_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Golden Bell Hoi An Boutique Villa",
                "description": "Includes a good breakfast and with large rooms this mid-option is perfect if you want good comfort at a reasonable price.",
                "image": "https://live.staticflickr.com/65535/50505008351_87f54b7f92_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Prana Boutique Hotel and Apartments",
                "description": "Near the Old Town, and with a nice view to Thu Bon River, this more expensive Hotel is a reliable option to relax and enjoy some time at the pool.",
                "image": "https://live.staticflickr.com/65535/50505008326_e18253c422_o.jpg",
                "link": "/"
            }
        ]
    }
}
