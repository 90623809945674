import React from 'react';
import CityGuide from '../../../../components/guides/CityComponent';
import Footer from '../../../../components/FooterComponent';

import { HoiAn } from '../../../../guides/countries/vietnam/hoi-an';

function HoiAnPage(props) {
    return (
        <div>
            <CityGuide city={ HoiAn } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default HoiAnPage;
